<template>
  <div class="page">
    <navbar index="0" />
    <banner>
      <img src="../assets/img/banner.jpg" class="img-fluid" alt="之愈医疗">
    </banner>
    <div class="main-content">

      <div class="section-1">
        <h1>找大夫</h1>
        <div class="join-tab">
          <div class="tab-item" :class="tabIndex == '0' ? 'active' : ''" @click.stop="switchIndex(0)">
            <p>按疾病找大夫</p>
          </div>
          <div class="tab-item" :class="tabIndex == '1' ? 'active' : ''" @click.stop="switchIndex(1)">
            <p>按医院找大夫</p>
          </div>
          <div class="tab-item" :class="tabIndex == '2' ? 'active' : ''" @click.stop="switchIndex(2)">
            <p>按科室找大夫</p>
          </div>
        </div>
        <div class="tab-content">
          <div class="content-item" v-for="(item, index) in indexData" :key="index" v-show="tabIndex == index ? true : false">
            <div class="item-item" v-for="(value, key) in item" :key="key">
              <a href="#">{{value.name}}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="section-2">
        <img src="../assets/img/industry.jpg" class="img-fluid" alt="">
        <div class="txt">
          <h1> THE 我们帮助的行业！<br>INDUSTRY WE HELP</h1>
          <p>拥有数量众多的优质医生群体，让您就医更便捷，咨询更方便</p>
        </div>
      </div>

      <div class="section-3">
        <div class="intro-img">
          <img src="../assets/img/intro.jpg" class="img-fluid" alt="">
        </div>
        <div class="intro-content">
          <h1>公司简介</h1>
          <p>江苏之愈医疗科技有限公司</p>
          <div>江苏之愈医疗科技有限公司在医院/医生信息查询、图文问诊、电话问诊、远程视频门诊、门诊精准预约、诊后疾病管理、家庭医生、疾病知识科普等多个领域取得显著成果，受到了医生、患者的广泛信赖。<br><br>
          江苏之愈医疗科技有限公司拥有数量众多的优质医生群体。收录全国近1万家正规医院79万余位医生信息。在这些活跃医生中，三甲医院的医生比例占到73%。 <br><br>
          用户可以通过好PC版网站、微信公众号、微信小程序等多个平台，方便地联系到24万公立医院的医生，一站式解决线上服务、线下就诊等各种医疗问题。</div>
        </div>
      </div>

      <div class="section-4">
          <div class="section-title">
            <img src="../assets/img/news.jpg" class="img-fluid" alt="">
            <div class="txt">
              <h2>新闻资讯</h2>
              <h1>NEWS</h1>
            </div>
          </div>
          <div class="section-content">
            <div class="news-item">
              <div class="news-img">
                <img src="../assets/img/news_1.png" class="img-fluid" alt="">
              </div>
              <div class="news-title">服用靶向药的几点注意事项</div>
            </div>
            <div class="news-item">
              <div class="news-img">
                <img src="../assets/img/news_2.png" class="img-fluid" alt="">
              </div>
              <div class="news-title">肺癌微创前术后应该吃什么呢</div>
            </div>
            <div class="news-item">
              <div class="news-img">
                <img src="../assets/img/news_3.png" class="img-fluid" alt="">
              </div>
              <div class="news-title">腰椎间盘突出症的微创治疗</div>
            </div>
          </div>
      </div>

      <div class="section-5">
        <div class="section-content">
          <div class="section-title">
            <h1>我们的优势 OUR<br>ADVANTAGES</h1>
          </div>
          <div class="advant-content">
            <div class="advant-item">
              <div class="advant-img">
                <img src="../assets/img/advant_2.png" alt="">
              </div>
              <div class="title">创新</div>
              <div class="desc">我们采用模块化和可移动的方式，为您提供灵活的问诊方式，帮助您自信的迎接未来。</div>
            </div>
            <div class="advant-item">
              <div class="advant-img">
                <img src="../assets/img/advant_3.png" alt="">
              </div>
              <div class="title">一对一精准服务</div>
              <div class="desc">健康顾问一对一服务，免费提供治疗解决方案</div>
            </div>
            <div class="advant-item">
              <div class="advant-img">
                <img src="../assets/img/advant_4.png" alt="">
              </div>
              <div class="title">专业技术团队</div>
              <div class="desc">我们对客户的理解无人能及，医生团队拥有丰富的行业技术经验</div>
            </div>
            <div class="advant-item">
              <div class="advant-img">
                <img src="../assets/img/advant_1.png" alt="">
              </div>
              <div class="title">完善的售后服务体系</div>
              <div class="desc">全方位服务支持，确保及时为您解决问题</div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-6">
        <div class="section-content">
            <div class="section-item">
              <p class="title">联系我们</p>
              <div class="contact-us">
                <div><span>地址：</span><span>南京市栖霞区八卦洲街道鹂岛路270号卦洲创业园A栋办公楼1-0832</span></div>
                <div><span>电话：</span><span>025-58862676</span></div>
                <div><span>邮箱：</span><span>services@zhiyuyl.cn</span></div>
              </div>
            </div>
            <div class="section-item">
              <p class="title">关于我们</p>
              <div>
                <a href="#">首页</a>
                <a href="#">简介</a>
                <a href="#">联系我们</a>
              </div>
            </div>
            <div class="section-item">
              <p class="title">产品</p>
              <div>
                <a href="#">行业与服务</a>
                <a href="#">新闻</a>
              </div>
            </div>
        </div>
      </div>

      <div class="section-7">
        Copyright © 2022 之愈医疗科技有限公司 版权所有 <a href="https://beian.miit.gov.cn" target="__blank">苏ICP备2022031098号-1</a>
      </div>

    </div>
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  export default {
    name: 'Home',
    computed: {
      initBannerHeight: () => {
        const W = 1920, H = 460, _W = window.screen.width
        return 'height: ' + (H * _W) / W + 'px'
      }
    },
    data() {
      return {
        tabIndex: 0,
        indexData: [
          [{
            name: '高血压'
          },{
            name: '冠心病'
          },{
            name: '高颈椎病'
          },{
            name: '不孕不育'
          },{
            name: '月经失调'
          },{
            name: '骨折'
          },{
            name: '子宫肌瘤'
          },{
            name: '糖尿病'
          },{
            name: '小儿感冒'
          },{
            name: '胆结石'
          },{
            name: '乳腺癌'
          },{
            name: '胃炎'
          },{
            name: '偏头痛'
          },{
            name: '肺癌'
          },{
            name: '脑梗塞'
          }],
          [{
            name: '南京市鼓楼医院'
          },{
            name: '江苏省中医院'
          },{
            name: '南京市儿童医院'
          },{
            name: '南京市第一院'
          },{
            name: '江苏省口腔医院'
          },{
            name: '江苏省人民医院'
          },{
            name: '南京市鼓楼医院江北分院'
          }],
          [{
            name: '心脑血管科'
          },{
            name: '生殖科'
          },{
            name: '骨科'
          },{
            name: '妇科'
          },{
            name: '神经科'
          },{
            name: '儿科'
          },{
            name: '消化科'
          },{
            name: '胸科'
          },{
            name: '外科'
          },{
            name: '脑科'
          }]
        ]
      }
    },
    methods: {
      switchIndex(index) {
        if (this.tabIndex == index) return
        this.tabIndex = index
      }
    },
    components: {
      navbar,
      banner
    }
  }
</script>

<style lang="scss" scoped>
  .main-content {
    position: relative;
    margin: 0 auto;
    height: auto;
  }
  .section-1 {
    min-width: 1300px;
    max-width: 1440px;
    margin: 0 auto;
    padding: 110px 0;
    .join-tab {
      margin-top: 100px;
      display: flex;
      .tab-item {
        padding: 0 40px;
        position: relative;
        flex: 0 0 auto;
        text-align: center;
        cursor: pointer;
        p {
          margin-bottom: 16px;
          line-height: 1;
          font-size: 31px;
          color: #212121
        }
        &.active {
          p {
            font-weight: bold;
          }
          &::after {
            content: '';
            width: 100%;
            height: 6px;
            border-radius: 3px;
            background-color: #535353;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: width .2s ease-in;
          }
        }
      }
    }
    .tab-content {
      box-sizing: content-box;
      padding: 100px 50px;
      .content-item{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .item-item {
          flex: 0 0 25%;
          padding: 15px 0;
          line-height: 1.5;
          text-align: center;
          color: #191919;
          a {
            font-size: 22px;
            color: #191919;
          }
        }
      }
    }
  }
  .section-2 {
    position: relative;
    .txt {
      padding-bottom: 126px;
      width: 1300px;
      height: auto;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      color: #fff;
      h1 {
        font-size: 106px;
        margin-bottom: 45px;
      }
      p {
        margin: 0;
        padding-top: 45px;
        font-size: 20px;
        border-top: 1px solid #fff;
      }
    }
  }
  .section-3 {
    margin: 0 auto;
    padding: 100px 0;
    width: 1300px;
    display: flex;
    .intro-img {
      margin-right: 200px;
      width: 400px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .intro-content {
      flex: 1;
      color: #2B2B2B;
      h1 {
        margin-bottom: 80px;
        font-size: 40px;
      }
      p {
        margin-bottom: 30px;
        font-size: 30px;
      }
      div {
        line-height: 1.4;
        font-size: 18px;
      }
    }
  }
  .section-4 {
    margin: 0 auto;
    padding: 100px 0;
    .section-title {
      position: relative;
      .txt {
        // width: 1300px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(10%, 30px);
        color: #fff;
        h2 {
          font-size: 80px;
        }
        h1 {
          font-size: 189px;
        }
      }
    }
    .section-content {
      margin: 0 auto;
      padding: 100px 0;
      // width: 1300px;
      display: flex;
      justify-content: space-between;
      .news-item {
        flex: 0 0 30%;
        cursor: pointer;
        .news-img {
          width: 100%;
          height: auto;
          text-align: center;
        }
        .news-title {
          margin-top: 30px;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 25px;
          color: #191919;
        }
      }
    }
  }
  .section-5 {
    padding: 100px 0;
    background: url('../assets/img/advantages.jpg') top center no-repeat;
    background-size: 100% 100%;
    .section-content {
      margin: 0 auto;
      width: 1300px;
      .section-title {
        margin-bottom: 100px;
        color: #fff;
        text-align: right;
        h1 {
          font-size: 80px;
        }
      }
      .advant-content {
        display: flex;
        .advant-item{
          flex: 1;
          margin-right: 50px;
          text-align: center;
          color: #fff;
          &:last-child {
            margin-right: 0;
          }
          .advant-img {
            width: 100%;
            margin-bottom: 85px;
          }
          .title {
            margin-bottom: 80px;
            font-size: 30px;
          }
        }
      }
    }
  }
  .section-6 {
    padding: 60px 0;
    background-color: #262628;
    .section-content {
      margin: 0 auto;
      width: 1300px;
      display: flex;
      justify-content: space-between;
      .section-item {
        padding: 0 100px;
        text-align: center;
        &:first-child {
          // flex: 1;
          text-align: left;
          // padding-left: 150px;
          .contact-us {
            font-size: 14px;
            color: #c8c8c8;
            div {
              display: flex;
              span{
                &:first-child {
                  width: 50px;
                }
              }
              // justify-content: space-between;
            }
          }
        }
        .title {
          margin-bottom: 30px;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
        }
        a {
          margin-bottom: 16px;
          line-height: 1;
          font-size: 16px;
          display: block;
          color: #c8c8c8;
        }
      }
    }
  }
  .section-7 {
    padding: 20px 0;
    text-align: center;
    background-color: #262628;
    border-top: 1px solid #A7A7A7;
    color: #a8a8a8;
    a {
      color: #c8c8c8;
    }
  }
</style>
