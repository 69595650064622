<template>
  <div class="nav-wrapper" :class="absolute ? 'nav-absolute' : ''">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/img/logo.png" width="192" height="72" title="诚派科技" alt="诚派科技">
      </router-link>
    </div>
    <div class="nav-items">
      <div class="nav-item">
        <router-link to="/" class="item" :class="index == '0' ? 'on' : ''">首页</router-link>
      </div>
      <div class="nav-item">
        <router-link to="/" class="item" :class="index == '1' ? 'on' : ''">简介</router-link>
      </div>
      <div class="nav-item">
        <router-link to="/" class="item" :class="index == '2' ? 'on' : ''">产品</router-link>
      </div>
      <div class="nav-item">  
        <a href="/" class="item" :class="index == '3' ? 'on' : ''">行业与服务</a>
      </div>
      <!-- <div class="nav-item">
        <a href="/" class="item" :class="index == '4' ? 'on' : ''">愿景</a>
      </div> -->
      <div class="nav-item">
        <a href="/" class="item" :class="index == '5' ? 'on' : ''">新闻与媒体</a>
      </div>
      <div class="nav-item">
        <a href="/" class="item" :class="index == '5' ? 'on' : ''">联系我们</a>
      </div>
      <div class="nav-item apps">
        <router-link to="/Download" class="item" :class="index == '6' ? 'on' : ''">下载APP</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'navbar',
    props: {
      index: {
        type: [String, Number],
        default: '0'
      },
      sub: {
        type: [String, Number],
        default: ''
      },
      absolute: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        subIndex: this.sub
      }
    },
    watch: {
      $route (to) {
        if (to == '') {
          this.subIndex = 0
        } else {
          this.hashHandle(to.hash)
        }
      }
    },
    created () {
      this.hashHandle(this.$route.hash)
    },
    methods: {
      hashHandle (routeHash) {
        let subIndex = routeHash.split('#');
        if (subIndex.length == 2 && subIndex[1] != '') {
          this.subIndex = subIndex[1]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 35px 0 20px 0;
    min-width: 1300px;
    border-bottom: 3px solid rgba(255, 255, 255, .3);
    position: relative;
    &::after, &::before {
      content: '';
      width: 20px;
      height: 7px;
      display: inline-block;
      position: absolute;
      bottom: -5px;
      left: 0;
      background-color: #D5D5D5;
      border-radius: 2px;
    }
    &::before {
      left: auto;
      right: 0;
    }
    &.nav-absolute {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 20;
    }
    .nav-items {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      .nav-item {
        position: relative;
        a.item {
          display: inline-block;
          position: relative;
          margin: 0 40px;
          font-size: 16px;
          color: #fff;
        }
        &:hover, .item.on{
          color: #00FFF5;
        }
        &:hover .item::after, .item.on::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -14px;
          display: inline-block;
          width: 100%;
          height: 2px;
          color: #00FFF5;
          background-color: #00FFF5;
        }
        &.apps {
          a.item {
            padding: 8px 20px;
            font-size: 14px;
            font-weight: bold;
            color: #fff!important;
            background-color: #00FFF5;
            border-radius: 2px;
          }
          &:hover .item::after, .item.on::after {
            display: none!important;
          }
        }
      }
    }
  }
</style>