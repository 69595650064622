<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
  * {
    padding: 0;
    margin: 0;
  }
  html, body {
    font-family: PingFang SC, Avenir, Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #000; */
  }
  ul, li {
    list-style: none;
  }
  a, a:hover {
    text-decoration: none!important;
  }
  #app {
    width: 100%;
    height: auto;
  }
  .banner {
    font-size: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  ::v-deep ::-webkit-scrollbar-track-piece {
    background: #d3dce6;
    }
  ::v-deep ::-webkit-scrollbar {
    width: 4px;
  }
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #f0f3f7;
    border-radius: 2px;
  }
</style>
