import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
// import Privacy from '@/views/Privacy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue')
  },
  {
    path: '/privacyXiaobai',
    name: 'PrivacyXiaobai',
    component: () => import('@/views/PrivacyXiaobai.vue')
  },
  {
    path: '/privacyAssistant',
    name: 'PrivacyAssistant',
    component: () => import('@/views/PrivacyAssistant.vue')
  },
  {
    path: '/Download',
    name: 'Download',
    component: () => import('@/views/Apps.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  srcollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        x: 0,
        y: 0
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
